







































































import {
  Vue,
  Component,
  Ref,
} from 'vue-property-decorator';

import { DataOptions } from 'vuetify';

import DDAConciliation from '@/domain/models/DDAConciliation';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';
import IDDAConciliationFilter from '@/domain/interfaces/IDDAConciliationFilter';

import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';

import DdaConciliationFilter from './components/Filter.vue';
import DdaConciliationList from './components/List.vue';
import { formateErrorForNotification } from './utils';

@Component({
  components: {
    DdaConciliationFilter,
    DdaConciliationList,
  },
})
export default class DDDAConciliationPage extends Vue {
  @Ref('dda-conciliation-filter') ddaConciliationFilter!: DdaConciliationFilter;

  readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  showFilter: boolean = true;
  showList: boolean = false;
  loading: boolean = false;

  sortBy: string = '';
  sortDesc: boolean = false;

  currentData: IDDAConciliationFilter = {} as IDDAConciliationFilter;

  list: Array<DDAConciliation> = [];

  get toggleIcon(): string {
    return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }

  get toggleTooltip(): string {
    return this.showFilter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
  }

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  handleToggle(): void {
    this.showFilter = !this.showFilter;
  }

  handleGoToHistory(): void {
    this.$router.push({ path: 'conciliacoes-de-dda/historico' });
  }

  handleValidate(): void {
    this.ddaConciliationFilter.validate();
  }

  async handleLoad(data: IDDAConciliationFilter): Promise<void> {
    try {
      this.$dialog.startLoading();
      const {
        companies,
        initialDueDate,
        endDueDate,
        initialProcessingDate,
        endProcessingDate,
        status,
        suppliers,
        sort,
        allCompanies,
      } = data;

      const parms = {
        initial_due_date: initialDueDate,
        end_due_date: endDueDate,
        initial_processing_date: initialProcessingDate,
        end_processing_date: endProcessingDate,
        status,
        suppliers,
        sort,
      };

      if (parms.sort) this.sort(parms.sort);

      const items = await this.DDAConciliationRepository
        .getDDAConciliations(this.groupId, companies.length ? companies : allCompanies, parms);

      this.filterParametersRepository.setFilter(GroupFilterParametersEnum.DDA_CONCILIATION_LIST, [
        { key: 'initial_due_date_dda_conciliation_list', value: data.initialDueDate },
        { key: 'end_due_date_dda_conciliation_list', value: data.endDueDate },
        { key: 'initial_processing_date_dda_conciliation_list', value: data.initialProcessingDate },
        { key: 'end_processing_date_dda_conciliation_list', value: data.endProcessingDate },
        { key: 'companies_dda_conciliation_list', value: JSON.stringify(data.companies ?? []) },
        { key: 'status_dda_conciliation_list', value: JSON.stringify(data.status ?? []) },
        { key: 'sort_dda_conciliation_list', value: parms.sort },
      ]);

      this.currentData = data;

      if (items.length) {
        this.list = items;
        this.showFilter = false;
        this.showList = true;
      } else {
        this.$notification.warn('Nenhuma conciliação de DDA foi encontrada! Por favor, tente novamente com outros parâmetros.');
        this.list = [];
        this.showFilter = true;
        this.showList = false;
      }
    } catch (error: any) {
      const errorMessage = formateErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  handleReload() {
    this.handleLoad(this.currentData);
  }

  updateSort(value: DataOptions) {
    const { sortBy, sortDesc } = value;
    this.sortBy = sortBy[0] || '';
    this.sortDesc = sortDesc[0] || false;
    if (!this.loading) this.handlerSort(this.sortBy, this.sortDesc);
  }

  sort(value: string): void {
    if (value.includes('-')) {
      this.sortBy = value.substring(1);
      this.sortDesc = true;
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }

  handlerSort(by: string, desc: boolean): void {
    const params: IDDAConciliationFilter = {
      ...this.currentData,
    };

    const order = desc ? '-' : '';
    params.sort = by ? `${order}${by}` : undefined;

    this.handleLoad(params);
  }
}
