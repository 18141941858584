
























































import InputRules from '@/helpers/InputRulesHelper';
import RemittanceScheduleRepository from '@/repositories/RemittanceScheduleRepository';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'UpdateRemittanceScheduleScheduleConfigModal',

  data() {
    const inputRules: InputRules = new InputRules();
    const remittanceScheduleConfigurationRepository = new RemittanceScheduleRepository();

    const bank: Number | null = null;
    const banks: Array<{ text: string; value: number }> = [];

    return {
      inputRules,
      bank,
      banks,
      remittanceScheduleConfigurationRepository,
    };
  },

  props: {
    isUpdateBankModalOpen: {
      required: true,
      type: Boolean,
    },

    companyGroupId: {
      required: true,
      type: Number,
    },
  },

  created() {
    this.loadBanks();
  },
  methods: {
    close() {
      this.$emit('close');
    },

    action() {
      this.$emit('update', this.bank);
    },

    async loadBanks() {
      try {
        const banks = await this.remittanceScheduleConfigurationRepository.getBanks(
          this.companyGroupId,
        );

        this.banks = banks.map((bank) => {
          const {
            banco, agencia, conta, id: value,
          } = bank;
          const text = `${banco} ${agencia} ${conta}`;
          return { text, value };
        });
      } catch (error) {
        this.$notification.error(
          'Houve um problema ao requisitar o banco do dia.',
        );
      }
    },
  },
});
