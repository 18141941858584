export default class RemittanceSchedule {
    constructor(
        public id: number,
        public company_name: string,
        public description: string,
        public bank: string,
        public agency: string,
        public account: number,
    ) {}

    static make(data: any) {
        return new RemittanceSchedule(
            data.id,
            data.company_name,
            data.description,
            data.bank,
            data.agency,
            data.account,
        );
    }
}
