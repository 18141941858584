import Bank from '@/domain/models/Bank';
import BankConfig from '@/domain/models/BankConfig';
import RemittanceSchedule from '@/domain/models/remittanceSchedule/RemittanceSchedule';
import Repository from '@/repositories/base/Repository';

export default class RemittanceScheduleRepository extends Repository {
    public async getRemittanceSchedule(companyGroupId: number) {
        const { data: response } = await this.datasource.get(`/company-groups/${companyGroupId}/remittance-schedule-configs`);

        return response.data.map(RemittanceSchedule.make);
    }

    public async getBanks(companyGroupId: number): Promise<Array<BankConfig>> {
        const { status, data: response } = await this.datasource.get(`/company-groups/${companyGroupId}/bank-configs/send-type-is-api`);

        if (status !== 200) {
            throw new Error('Ops, aconteceu algum problema ao buscar o banco do dia');
         }

        return response.data.map(BankConfig.make);
    }

    public async updateSchedules(companyGroupId: number, bankId: number): Promise<void> {
         const { status } = await this.datasource.send(`/company-groups/${companyGroupId}/remittance-schedule-configs/set-default-bank-config`, {
            bank_config_id: bankId,
         });

         if (status !== 200) {
            throw new Error('Ops, aconteceu algum problema ao atualizar o banco do dia');
         }
    }
}
