




































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import RemittanceSchedule from '@/domain/models/remittanceSchedule/RemittanceSchedule';
import RemittanceScheduleRepository from '@/repositories/RemittanceScheduleRepository';
import RemittanceScheduleConfigList from './components/RemittanceScheduleConfigList.vue';
import UpdateRemittanceScheduleConfigModal from './components/UpdateRemittanceScheduleConfigModal.vue';

export default defineComponent({
  components: {
    RemittanceScheduleConfigList,
    UpdateRemittanceScheduleConfigModal,
  },
  name: 'RemittanceScheduleConfig',

  data() {
    const isLoading = false;
    const isUpdateBankModalOpen = false;

    const remittanceConfigurations: Array<RemittanceSchedule> = [];
    const repository: RemittanceScheduleRepository = new RemittanceScheduleRepository();

    return {
      isLoading,
      remittanceConfigurations,
      repository,
      isUpdateBankModalOpen,
    };
  },

  computed: {
    ...mapState({
      companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
    }),
    companyId(): number {
      return this.$store.getters['authentication/companyIds'][0];
    },
  },

  created() {
    this.getRemittanceSchedules();
  },

  methods: {
    async getRemittanceSchedules() {
      try {
        this.isLoading = true;
        this.$dialog.startLoading();

        this.remittanceConfigurations = await this
        .repository
        .getRemittanceSchedule(this.companyGroupId);
      } catch (error) {
        this.$notification.error(
          'Houve um problema ao requisitar o banco do dia.',
        );
      } finally {
        this.$dialog.stopLoading();
        this.isLoading = false;
      }
    },

    handleModalUpdateBank() {
      this.isUpdateBankModalOpen = true;
    },

    async handleUpdateBank(bankId: number) {
      try {
        this.isLoading = true;
        this.$dialog.startLoading();

        await this
        .repository
        .updateSchedules(this.companyGroupId, bankId);

        this.isUpdateBankModalOpen = false;

        await this.getRemittanceSchedules();
      } catch (error) {
        this.$notification.error(
          'Houve um problema ao requisitar o banco do dia.',
        );
      } finally {
        this.$dialog.stopLoading();
        this.isLoading = false;
      }
    },
  },
});
