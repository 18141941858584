








import RemittanceSchedule from '@/domain/models/remittanceSchedule/RemittanceSchedule';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'RemittanceScheduleConfigList',
  props: {
    remittanceConfigurations: {
      required: true,
      type: [Array as () => Array<RemittanceSchedule>],
    },

    isLoading: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    const headers = [
      {
        text: 'Empresa',
        value: 'company_name',
      },
      {
        text: 'Banco',
        value: 'bank',
      },
      {
        text: 'Agencia',
        value: 'agency',
      },
      {
        text: 'Número da conta',
        value: 'account',
      },
    ];

    return {
      headers,
    };
  },
});
